import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  sizes: {
    container: {
      xs: '30em', // 480px
      sm: '40em', // 640px
      md: '58em', // 928px
      lg: '80em', // 1280px
      xl: '96em', // 1536px
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'medium',
        letterSpacing: 'normal',
      },
      variants: {
        link: {
          ':hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'blue.500',
        ':hover': {
          textDecoration: 'none',
        },
      },
      variants: {
        subhead: {
          fontWeight: 'medium',
          fontSize: 'sm',
          lineHeight: 'base',
          letterSpacing: 'normal',
        },
      },
    },
    Text: {
      variants: {
        subhead: {
          fontWeight: 'medium',
          fontSize: 'sm',
          lineHeight: 'base',
          letterSpacing: 'normal',
        },
        caption: {
          fontWeight: 'light',
          fontSize: 'xs',
          lineHeight: 'base',
          letterSpacing: 'normal',
        },
        quote: {
          fontStyle: 'italic',
          fontWeight: 'light',
          lineHeight: 'tall',
          pb: '0.65em',
        },
      },
    },
    Heading: {
      variants: {
        md: {
          fontWeight: 'medium',
          fontSize: 'xl',
          lineHeight: 'tall',
          letterSpacing: 'normal',
        },
        sm: {
          fontWeight: 'medium',
          fontSize: 'lg',
          lineHeight: 'normal',
          letterSpacing: 'normal',
        },
        xs: {
          fontWeight: 'medium',
          fontSize: 'md',
          lineHeight: 'normal',
          letterSpacing: 'normal',
        },
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'gray.200',
        opacity: 1,
      },
    },
    Tooltip: {
      variants: {
        outline: 'red.400',
      },
    },
  },
  colors: {
    black: 'gray.800',
    blue: {
      50: '#e3f2fd',
      100: '#bbdefb',
      200: '#90caf9',
      300: '#64b5f6',
      400: '#42a5f5',
      500: '#2196f3',
      600: '#1e88e5',
      700: '#1976d2',
      800: '#1565c0',
      900: '#0d47a1',
    },
  },
  fonts: {
    heading: 'Roboto, Helvetica, Arial, sans-serif',
    body: 'Roboto, Helvetica, Arial, sans-serif',
  },
  styles: {
    global: {},
  },
});

export default theme;
