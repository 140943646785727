import { createContext, useContext, useState } from 'react';
import { SingleValue } from 'react-select';

import { SortOption, sortOptions } from '../hooks/use-comp-sorting';

type AppContextProps = {
  option: SingleValue<SortOption>;
  setOption: React.Dispatch<React.SetStateAction<SingleValue<SortOption>>>;
};

const AppContext = createContext<AppContextProps>({
  option: sortOptions[3],
  setOption: () => undefined,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider: React.FC = ({ children }) => {
  const [option, setOption] = useState<SingleValue<SortOption>>(sortOptions[3]);

  const value: AppContextProps = {
    option,
    setOption,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
