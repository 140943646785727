import { lazy, ReactElement, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { path } from './config';
import { Link } from './layout';
import { Fallback } from './components/fallback';

const Account = lazy(() => import('./pages/Account'));
const Auth = lazy(() => import('./pages/Auth'));
const Comp = lazy(() => import('./pages/Comp'));
const Email = lazy(() => import('./pages/Email'));
const Homecomp = lazy(() => import('./pages/Homecomp'));
const Layout = lazy(() => import('./layout'));
const Login = lazy(() => import('./pages/Login'));
const Map = lazy(() => import('./pages/Map'));
const Market = lazy(() => import('./pages/Market'));
const Home = lazy(() => import('./pages/Home'));
const Neighborhood = lazy(() => import('./pages/Neighborhood'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Preferences = lazy(() => import('./pages/Preferences'));
const Property = lazy(() => import('./pages/Property'));
const Reset = lazy(() => import('./pages/Reset'));
const Success = lazy(() => import('./pages/Success'));
const Top10 = lazy(() => import('./pages/Top10'));

type MenuLink = Link & { element: ReactElement | null };

const links: Link[] = [
  { path: path.top10, title: 'Top 10' },
  { path: path.neighborhood, title: 'Neighborhood' },
  { path: path.market, title: 'Market' },
  { path: path.map, title: 'Map' },
];

const handleMenuEle = (index: number): ReactElement | null => {
  switch (index) {
    case 0: {
      return <Top10 />;
    }
    case 1: {
      return <Neighborhood />;
    }
    case 2: {
      return <Market />;
    }
    case 3: {
      return <Map />;
    }
    default:
      return null;
  }
};

const menuLinks = links.map<MenuLink>(({ path, title }, i) => ({
  element: handleMenuEle(i),
  path,
  title,
}));

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<Fallback />}>
      <Routes>
        <Route element={<Layout links={links} />}>
          <Route path={path.home} element={<Home />} />
          <Route path={path.account} element={<Account />} />
          <Route path={path.homecomp} element={<Homecomp />} />
          {menuLinks.map(({ element, path, title }) => (
            <Route key={title} element={element} path={path} />
          ))}
          <Route path={path.comp} element={<Comp />} />
          <Route path={path.property} element={<Property />} />
        </Route>
        <Route path={path.auth} element={<Auth />} />
        <Route path={path.email} element={<Email />} />
        <Route path={path.login} element={<Login />} />
        <Route path={path.preferences} element={<Preferences />} />
        <Route path={path.reset} element={<Reset />} />
        <Route path={path.success} element={<Success />} />
        <Route path={path.notFound} element={<NotFound />} />
      </Routes>
    </Suspense>
  </BrowserRouter>
);
