import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';

import { initFirebase } from './firebase/app';
import { FirebaseProvider } from './providers/firebase';
import { AppProvider } from './providers/app';
import { AppRouter } from './AppRouter';
import theme from './theme';

const App: React.FC = () => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <FirebaseProvider hostname='homecomp' initFirebase={initFirebase}>
          <ChakraProvider resetCSS theme={theme}>
            <AppProvider>
              <AppRouter />
            </AppProvider>
          </ChakraProvider>
        </FirebaseProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
};

export default App;
