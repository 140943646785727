import { createContext, useContext, useEffect, useState } from 'react';
import { ClientOptions } from '@listingops/clients';
import { getToken, AppCheck } from 'firebase/app-check';
import {
  applyActionCode,
  confirmPasswordReset,
  EmailAuthProvider,
  getAuth,
  getIdToken,
  isSignInWithEmailLink,
  onAuthStateChanged,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut as authSignOut,
  updatePassword,
  User,
  UserCredential,
  verifyPasswordResetCode,
} from 'firebase/auth';

import { hostUrl, prod } from '../env-constants';
import { initAppCheck } from '../firebase/app-check';

export type ConfirmParams = {
  code: string;
  newPassword: string;
};

type FirebaseContextProps = {
  confirmPassword: (params: ConfirmParams) => Promise<void>;
  emailAndPasswordLogin: (
    email: string,
    password: string
  ) => Promise<UserCredential | null>;
  options: ClientOptions;
  reauthenticate: (password: string) => Promise<UserCredential | null>;
  setNewPassword: (newPassword: string) => Promise<void>;
  signInWithLink: (
    email: string,
    link: string
  ) => Promise<UserCredential | null>;
  signOut: (signIn: string) => Promise<void>;
  uid: string;
  userEmail: string;
  verifyActionCode: (oobCode: string) => Promise<void>;
  verifyResetCode: (code: string) => Promise<string>;
  verifySignInLink: (link: string) => boolean;
};

const options: ClientOptions = {
  getAppToken: () => new Promise(() => ''),
  getAuthToken: () => new Promise(() => ''),
  hostname: undefined,
  retries: undefined,
};

const FirebaseContext = createContext<FirebaseContextProps>({
  confirmPassword: () => new Promise(() => undefined),
  emailAndPasswordLogin: () => new Promise(() => undefined),
  options,
  reauthenticate: () => new Promise(() => undefined),
  setNewPassword: () => new Promise(() => undefined),
  signInWithLink: () => new Promise(() => undefined),
  signOut: () => new Promise(() => undefined),
  uid: '',
  userEmail: '',
  verifyActionCode: () => new Promise(() => undefined),
  verifyResetCode: () => new Promise(() => undefined),
  verifySignInLink: () => false,
});

export const useFirebase = () => useContext(FirebaseContext);

export type FirebaseProviderProps = {
  initFirebase: () => void;
  hostname: ClientOptions['hostname'];
};

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({
  children,
  initFirebase,
  hostname,
}) => {
  const [appCheck, setAppCheck] = useState<AppCheck | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [uid, setUid] = useState('');
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    initFirebase();
    const appCheck = initAppCheck();
    setAppCheck(appCheck);
  }, [initFirebase]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      setUser(user);

      if (user) {
        setUid(user.uid);
        setUserEmail(user.email || '');
      }

      if (!prod) {
        console.log('[User Firebase Provider]', user);
      }
    });
    return () => unsubscribe();
  }, []);

  const confirmPassword = ({ code, newPassword }: ConfirmParams) => {
    return confirmPasswordReset(getAuth(), code, newPassword);
  };

  const emailAndPasswordLogin = (
    email: string,
    password: string
  ): Promise<UserCredential> => {
    return signInWithEmailAndPassword(getAuth(), email, password);
  };

  const getAppToken = async () => {
    if (!appCheck) return '';
    try {
      const { token } = await getToken(appCheck);
      return token;
    } catch (error) {
      throw error;
    }
  };

  const getAuthToken = async () => {
    if (!user) return '';
    try {
      const token = await getIdToken(user);
      return token;
    } catch (error) {
      throw error;
    }
  };

  const reauthenticate = async (password: string) => {
    if (!user || !user.email) return null;
    const cred = EmailAuthProvider.credential(user.email, password);
    return reauthenticateWithCredential(user, cred);
  };

  const setNewPassword = async (newPassword: string) => {
    if (!user) return;
    return updatePassword(user, newPassword);
  };

  const signInWithLink = (email: string, link: string) => {
    return signInWithEmailLink(getAuth(), email, link);
  };

  const signOut = async (signIn: string) => {
    // useNavigate can only be used in the context of a <Router> component
    const redirect = () => window.location.replace(hostUrl + signIn);

    try {
      if (user) {
        await authSignOut(getAuth());
      }
      redirect();
    } catch (error) {
      redirect();
    }
  };

  const verifyActionCode = (oobCode: string): Promise<void> => {
    return applyActionCode(getAuth(), oobCode);
  };

  const verifyResetCode = (code: string) => {
    return verifyPasswordResetCode(getAuth(), code);
  };

  const verifySignInLink = (link: string) => {
    return isSignInWithEmailLink(getAuth(), link);
  };

  const value: FirebaseContextProps = {
    confirmPassword,
    emailAndPasswordLogin,
    options: { getAppToken, getAuthToken, hostname },
    reauthenticate,
    setNewPassword,
    signInWithLink,
    signOut,
    uid,
    userEmail,
    verifyActionCode,
    verifyResetCode,
    verifySignInLink,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
