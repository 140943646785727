export const dev = process.env.NODE_ENV === 'development';
export const prod = process.env.NODE_ENV === 'production';
export const hostUrl = process.env.REACT_APP_HOST_URL;
export const mapboxApiAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const reCaptchaKey = process.env.REACT_APP_RECAPTCHA;

// Firebase config
export const apiKey = process.env.REACT_APP_FB_API_KEY;
export const appId = process.env.REACT_APP_FB_APP_ID;
export const authDomain = process.env.REACT_APP_FB_AUTH_DOMAIN;
export const messagingSenderId = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const projectId = process.env.REACT_APP_FB_PROJECT_ID;
export const storageBucket = process.env.REACT_APP_FB_STORAGE_BUCKET;
