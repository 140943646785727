import { useCallback } from 'react';
import Select from 'react-select';
import { CompV1 } from '@listingops/types';
import { Flex, HStack, Text, useMediaQuery, useTheme } from '@chakra-ui/react';

import { useAppContext } from '../providers/app';
import { Compare } from '../icons';

enum Value {
  SET_BATHS_ASC = 'SET_BATHS_ASC',
  SET_BATHS_DESC = 'SET_BATHS_DESC',
  SET_BEDS_ASC = 'SET_BEDS_ASC',
  SET_BEDS_DESC = 'SET_BEDS_DESC',
  SET_LOT_SIZE_ASC = 'SET_LOT_SIZE_ASC',
  SET_LOT_SIZE_DESC = 'SET_LOT_SIZE_DESC',
  SET_MILES_AWAY_ASC = 'SET_MILES_AWAY_ASC',
  SET_MILES_AWAY_DESC = 'SET_MILES_AWAY_DESC',
  SET_PRICE_ASC = 'SET_PRICE_ASC',
  SET_PRICE_DESC = 'SET_PRICE_DESC',
  SET_PROP_STATUS_ASC = 'SET_PROP_STATUS_ASC',
  SET_PROP_STATUS_DESC = 'SET_PROP_STATUS_DESC',
  SET_SQFT_ASC = 'SET_SQFT_ASC',
  SET_SQFT_DESC = 'SET_SQFT_DESC',
}

export type SortOption = {
  readonly value: Value;
  readonly label: string;
};

export const sortOptions: readonly SortOption[] = [
  { value: Value.SET_PROP_STATUS_DESC, label: 'Sold (closed)' },
  { value: Value.SET_PROP_STATUS_ASC, label: 'For Sale (active)' },
  { value: Value.SET_PRICE_DESC, label: 'Price (high to low)' },
  { value: Value.SET_PRICE_ASC, label: 'Price (low to high)' },
  { value: Value.SET_MILES_AWAY_ASC, label: 'Miles away (nearest first)' },
  { value: Value.SET_MILES_AWAY_DESC, label: 'Miles away (farthest first)' },
  { value: Value.SET_SQFT_ASC, label: 'Square Feet (less to more)' },
  { value: Value.SET_SQFT_DESC, label: 'Square (more to less)' },
  { value: Value.SET_LOT_SIZE_ASC, label: 'Lot size (small to big)' },
  { value: Value.SET_LOT_SIZE_DESC, label: 'Lot size (big to small)' },
  { value: Value.SET_BEDS_ASC, label: 'Beds (less to more)' },
  { value: Value.SET_BEDS_DESC, label: 'Beds (more to less)' },
  { value: Value.SET_BATHS_ASC, label: 'Baths (less to more)' },
  { value: Value.SET_BATHS_DESC, label: 'Baths (more to less)' },
];

export const useCompSorting = () => {
  const [isMedia550] = useMediaQuery('(min-width: 550px)');
  const { option, setOption } = useAppContext();

  const sortComps = useCallback(
    (compA: CompV1, compB: CompV1): number => {
      switch (option?.value) {
        case Value.SET_BATHS_ASC: {
          return compA.baths - compB.baths;
        }
        case Value.SET_BATHS_DESC: {
          return compB.baths - compA.baths;
        }
        case Value.SET_BEDS_ASC: {
          return compA.beds - compB.beds;
        }
        case Value.SET_BEDS_DESC: {
          return compB.beds - compA.beds;
        }
        case Value.SET_LOT_SIZE_ASC: {
          const aLotSize = compA.rawLotSize || 0;
          const bLotSize = compB.rawLotSize || 0;
          return aLotSize - bLotSize;
        }
        case Value.SET_LOT_SIZE_DESC: {
          const aLotSize = compA.rawLotSize || 0;
          const bLotSize = compB.rawLotSize || 0;
          return bLotSize - aLotSize;
        }
        case Value.SET_MILES_AWAY_ASC: {
          return compA.milesFromSubject - compB.milesFromSubject;
        }
        case Value.SET_MILES_AWAY_DESC: {
          return compB.milesFromSubject - compA.milesFromSubject;
        }
        case Value.SET_PRICE_ASC: {
          return compA.rawPrice - compB.rawPrice;
        }
        case Value.SET_PRICE_DESC: {
          return compB.rawPrice - compA.rawPrice;
        }
        case Value.SET_PROP_STATUS_ASC: {
          const aStatus = compA.propertyStatus;
          const bStatus = compB.propertyStatus;
          if (aStatus < bStatus) return -1;
          if (aStatus > bStatus) return 1;
          // status must be equal
          return 0;
        }
        case Value.SET_PROP_STATUS_DESC: {
          const aStatus = compA.propertyStatus;
          const bStatus = compB.propertyStatus;
          if (aStatus > bStatus) return -1;
          if (aStatus < bStatus) return 1;
          // status must be equal
          return 0;
        }
        case Value.SET_SQFT_ASC: {
          const aSqft = compA.rawSqFt || 0;
          const bSqft = compB.rawSqFt || 0;
          return aSqft - bSqft;
        }
        case Value.SET_SQFT_DESC: {
          const aSqft = compA.rawSqFt || 0;
          const bSqft = compB.rawSqFt || 0;
          return bSqft - aSqft;
        }

        default:
          return 0;
      }
    },
    [option]
  );

  const CompSorting: React.FC = () => {
    const {
      colors: { blue, gray },
    } = useTheme();

    const blueShadow = `0 2px 0 0 ${blue[500]}`;
    const grayShadow = `0 1px 0 0 ${gray[200]}`;

    return (
      <HStack pl='1' boxShadow={isMedia550 ? grayShadow : 'none'}>
        <Flex alignItems='center'>
          <Text color='gray.500'>Sort</Text>
          <Compare size={5} color='gray.500' />
        </Flex>
        <Select
          defaultValue={option}
          options={sortOptions}
          onChange={setOption}
          styles={{
            control: (base, { isFocused }) => ({
              ...base,
              borderWidth: 0,
              boxShadow: isMedia550
                ? isFocused
                  ? blueShadow
                  : grayShadow
                : 'none',
              borderRadius: 0,
            }),
            input: styles => ({
              ...styles,
              minWidth: 198,
            }),
          }}
        />
      </HStack>
    );
  };

  return { CompSorting, sortComps };
};
