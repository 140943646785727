export enum path {
  account = '/account',
  auth = '/auth',
  comp = '/h/:hid/comp/:rid',
  email = '/email',
  home = '/home',
  homecomp = '/h/:hid',
  login = '/',
  map = '/h/:hid/map',
  market = '/h/:hid/market',
  neighborhood = '/h/:hid/neighborhood',
  notFound = '*',
  preferences = '/preferences/:uid',
  property = '/h/:hid/property/:rid',
  reset = '/reset',
  success = '/success',
  top10 = '/h/:hid/top10',
}
