import { getApps, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

import {
  apiKey,
  appId,
  authDomain,
  messagingSenderId,
  prod,
  projectId,
  storageBucket,
} from '../env-constants';

export const initFirebase = () => {
  const apps = getApps();
  if (apps.length) return;

  initializeApp({
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
  });

  if (!prod) {
    connectAuthEmulator(getAuth(), 'http://localhost:9099', {
      disableWarnings: true,
    });
    console.info('[Auth Emulator Connected]');

    connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
    console.info('[Firestore Emulator Connected]');
  }
};
